import {
  type Analytics,
  eventType as EventType,
} from '@iheartradio/web.analytics';

import { analytics } from './create-analytics';

type TrackClickType = {
  pageName: string;
  action?: string;
  sectionName?: string;
  filterType?: string;
  filterSelection?: string;
  station?: Analytics.Click['view']['station'];
  tab?: string | null;
};

export const trackClick = ({
  pageName,
  action = EventType.enum.Click,
  sectionName,
  filterType,
  filterSelection,
  station,
  tab,
}: TrackClickType) => {
  analytics.track({
    type: EventType.enum.Click,
    data: {
      pageName,
      action,
      window: {
        location: {
          href: window.location.href,
        },
      },
      view: {
        pageName,
        ...(station ? { station } : {}),
        ...(tab ? { tab } : {}),
        ...(sectionName ? { section: { name: sectionName } } : {}),
      },
      event: {
        location: `${pageName}|${action}`,
        ...(filterType && filterSelection ?
          {
            filter: {
              type: filterType,
              selection: filterSelection,
            },
          }
        : {}),
      },
    },
  });
};
